import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Button, Typography } from '@swagup-com/components';
import { Close } from '@material-ui/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import styles from './styles/membershipsBanner';
import gtm from '../../utils/gtm';
import useMembershipCheck from '../../hooks/useMembershipCheck';

const useStyles = makeStyles(styles);

const HolydayStar = ({ position = { x: 32, y: 32 }, size = 16 }) => {
  const classes = useStyles();
  return (
    <Box className={classes.bannerStar} style={{ top: position.y, right: position.x }}>
      <Box className={classes.bannerImageContainer} style={{ width: size, height: size }}>
        <img
          src="/images/dashboard/banner-star.svg"
          alt="hand"
          className={classes.bannerImage}
          style={{ position: 'absolute', height: 44, top: -2, left: 4 }}
        />
      </Box>
    </Box>
  );
};

const MembershipsBanner = () => {
  const [open, setOpen] = useState(true);

  const membershipCheck = useMembershipCheck();
  const CTAText = membershipCheck.isBasic ? 'Join Now' : 'Order Now';
  const CTALink = membershipCheck.isBasic ? '/membership/manage-membership-switch' : '/product-onboarding';
  const classes = useStyles();
  if (!open) return null;

  return (
    <Grid container alignItems="center" className={classes.container}>
      <Grid item>
        {membershipCheck.isBasic ? (
          <Box>
            <Typography variant="h5SemiBoldInter" className={classes.membershipTextPromoTime}>
              <span>*Limited time only*</span>
            </Typography>
            <Typography variant="h5SemiBoldInter" className={classes.membershipTextPromoDesc}>
              <span>Upgrade</span> to any membership
            </Typography>
          </Box>
        ) : (
          <Typography variant="h5SemiBoldInter" className={classes.membershipTextOrange}>
            2X <span>rewards</span>
          </Typography>
        )}
      </Grid>
      <Grid item>
        <Box>
          <Grid container alignItems="center" spacing={2} className={classes.membershipBasicContainer}>
            <Grid item>
              <Box className={classes.bannerImageContainer}>
                <img src="/images/dashboard/banner-hand.svg" alt="hand" className={classes.bannerImage} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" className={classes.membershipPromocContainer}>
          <Grid item>
            {membershipCheck.isBasic ? (
              <Box>
                <Typography variant="h5SemiBoldInter" className={classes.membershipTextWhiteUnderlineJoin}>
                  Get the rest of
                </Typography>
                <Typography variant="h5SemiBoldInter" className={classes.membershipTextWhiteUnderlineJoin}>
                  the Year on us
                </Typography>
              </Box>
            ) : (
              <>
                <Typography variant="h5SemiBoldInter" className={classes.membershipTextWhiteUnderlineJoin}>
                  On all Holiday
                </Typography>
                <Grid container>
                  <Grid item xs>
                    <Typography variant="h5SemiBoldInter" className={classes.membershipTextWhiteUnderlineJoin}>
                      orders
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Box className={classes.bannerImageContainerSanta}>
                      <img src="/images/dashboard/banner-santa.svg" alt="hand" className={classes.bannerImageSanta} />
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        {/* <Typography variant="h5SemiBoldInter" className={classes.membershipTextPromoDesc}>
          Join any membership and get <br /> the rest of the year <span>on us</span>
        </Typography> */}
        <Grid container justifyContent="center">
          <Grid item>
            <div style={{ position: 'relative' }}>
              <Button
                className={classes.membershipCTA}
                component={Link}
                to={{ pathname: CTALink }}
                onClick={() => gtm.onClick('Memberships Promo - Try it now')}
              >
                {CTAText}
              </Button>
            </div>
          </Grid>
        </Grid>
        {/* )} */}
      </Grid>
      <Grid item>
        <Box style={{ height: 90 }}>
          <IconButton className={classes.closeIconButton} onClick={() => setOpen(false)}>
            <Close className={classes.closeIcon} />
          </IconButton>
        </Box>
      </Grid>
      <HolydayStar position={{ x: '3%', y: '24%' }} size={8} />
      <HolydayStar position={{ x: '1%', y: '60%' }} size={14} />
      <HolydayStar position={{ x: '6%', y: '44%' }} size={8} />

      <Box className={classes.middleStarts}>
        <HolydayStar position={{ x: '25%', y: '4%' }} size={8} />
        <HolydayStar position={{ x: '27%', y: '40%' }} size={14} />
        <HolydayStar position={{ x: '23%', y: '58%' }} size={10} />
      </Box>

      <HolydayStar position={{ x: '54%', y: '4%' }} size={12} />
      <HolydayStar position={{ x: '67%', y: '58%' }} size={14} />
    </Grid>
  );
};

export default MembershipsBanner;
