import React, { useEffect, useState } from 'react';
import { Dialog, Divider, Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import SwipeableViews from 'react-swipeable-views';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import isEmpty from 'lodash/isEmpty';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNumber } from 'lodash';
import { CenteredGrid, Helmet } from '../shared';
import styles from './styles/storefrontsHome';
import { getChangedProperties, getPageLink, prepareArtworksOnS3 } from './storeCommon';
import { useCompany, useProfile } from '../../hooks';
import { storefronts, verifications } from '../../apis/storefrontsServices';
import gtm from '../../utils/gtm';
import storefrontServicesPaths from '../../helpers/storefrontsServicesPaths';
import seoTags from '../../apis/seoTags';
import { isHexColor } from '../shared/styles/utils';
// import { useQueryFilterValidated } from '../../hooks/useFilters';
// import ProductSelectionDrawer from './ProductSelectionDrawer';
import StoreTemplates from './StoreTemplates';
// import ProductSelection from './creationSteps/ProductSelection';
// import ShippingSettings from './creationSteps/ShippingSettings';
import PageContent from './creationSteps/PageContent';
import PageTheme from './creationSteps/PageTheme';
import PageBasics from './creationSteps/PageBasics';
// import ConfirmationPage from './creationSteps/ConfirmationPage';
import { CustomTooltip } from '../products/commonProductsElements';
import { ellipsisStyles } from '../shared/styles/commonStyles';
import storeTemplate from './dataTemplates/storeTemplate';
import StoreSettings from './creationSteps/StoreSettings';
import { useMembership } from '../../contexts/membershipContext';
import HeaderSettings from './creationSteps/HeaderSettings';
import useMembershipCheck from '../../hooks/useMembershipCheck';
import { emailRegex } from '../global/Forms/commonValidations';

const useStyles = makeStyles(styles);

const darkTheme = {
  theme: 'dark',
  backgroundColor: '#161C25',
  fontColor: '#D6D8DB',
  accentColor: '#3577D4',
  fontFamily: 'Inter',
  buttonStyle: 'Rounded',
  footer: 'unset'
};
const lightTheme = {
  theme: 'light',
  backgroundColor: '#FFFFFF',
  fontColor: '#131415',
  accentColor: '#9846DD',
  fontFamily: 'Gilroy',
  buttonStyle: 'Rounded',
  footer: 'unset'
};
const dataTemplate = {
  ...storeTemplate
};

const FormContainer = ({ children, title, step, maxSetps, top = 10, showDisclaimer }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="body3RegularInter" className={classes.steps}>{`Step ${step}/${maxSetps}`}</Typography>
      <Typography variant="h3BoldInter" className={classes.stepTitle}>
        {title}
      </Typography>
      <div
        className={classes.stepContainer}
        style={{
          paddingTop: top,
          maxHeight: showDisclaimer ? 'calc(100vh - 320px)' : 'calc(100vh - 292px)',
          paddingRight: 8
        }}
      >
        {children}
      </div>
    </>
  );
};

const splitStringProperties = properties => {
  const parts = properties.split('.');
  const firstPart = parts.shift(); // Get the first part
  const rest = parts.join('.'); // Join the rest of the parts back together

  return [firstPart, rest];
};

const extractPropertyAndIndex = input => {
  const regex = /(\w+)(\[(\d+)\])?/;
  const match = input.match(regex);

  if (match) {
    const [, word, , number] = match;
    return number ? [word, parseInt(number, 10)] : [word];
  }

  return [input];
};

const recursiveUpdate = (property, objectValue, value, distructure = false) => {
  let aux = distructure ? { ...objectValue } : objectValue;
  if (!property.includes('.')) {
    aux[property] = value;
    return aux;
  }
  const [currentProperty, nextProperties] = splitStringProperties(property);
  const [properProperty, indexProperty] = extractPropertyAndIndex(currentProperty);
  if (isNumber(indexProperty)) {
    const elements = aux[properProperty];
    aux = {
      ...aux,
      [properProperty]: elements.map((element, idx) =>
        idx === indexProperty
          ? recursiveUpdate(nextProperties, aux[properProperty][indexProperty], value, true)
          : element
      )
    };
  } else aux[currentProperty] = recursiveUpdate(nextProperties, aux[currentProperty], value, true);
  return aux;
};

const templateFields = [
  {
    name: 'headline',
    placeholder: 'Edit your Store Header',
    label: 'Heading text',
    required: true
  },
  {
    name: 'body',
    placeholder: 'Edit your Store Subtitle',
    label: 'Subtitle text',
    multiline: true,
    required: true
  },
  {
    name: 'callToActionButtonText',
    placeholder: 'Edit your Call to action Text',
    label: 'CTA text',
    required: true
  },
  {
    name: 'clientImage',
    placeholder: 'Change the Product Display Image',
    label: 'Display image',
    image: true
  }
];

const fontFamilies = [
  { value: 'Gilroy', label: 'Gilroy' },
  { value: 'Helvetica', label: 'Helvetica' },
  { value: 'Inter', label: 'Inter' }
];

const themeVars = [
  { key: 'backgroundColor', label: 'Background Color' },
  { key: 'fontColor', label: 'Font Color' },
  { key: 'accentColor', label: 'Accent Color' }
];

const themeVarsErrors = {
  fontColor: 'Invalid font color value',
  backgroundColor: 'Invalid background color value',
  accentColor: 'Invalid accent color value'
};

const skippedProperty = ['company', 'theme'];

const StorefrontsCreate = () => {
  const [store, setStore] = useState(dataTemplate);
  const [currentStep, setCurrentStep] = useState(1);
  const [artworkLoader, setArtworkLoader] = useState([]);
  const [nammingError, setNammingError] = useState();
  const [generalError, setGeneralError] = useState();

  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const { data: company } = useCompany();

  const { data: originalPage } = useQuery(storefrontServicesPaths.storefront(id), () => storefronts.get(id), {
    enabled: !!id
  });

  useEffect(() => {
    if (originalPage?.id) {
      setStore({
        ...originalPage,
        generalSettings: {
          ...originalPage.generalSettings,
          companyName: company.name,
          companyDisplayName: originalPage.generalSettings?.companyDisplayName || company.name
        }
      });
    }
  }, [company.name, originalPage]);

  useEffect(() => {
    document.getElementById('root').style.background = 'linear-gradient(90deg, rgba(255,255,255) 50%, #EBF1FB 50%)';
    return () => (document.getElementById('root').style.background = '#ffffff');
  }, []);

  useEffect(() => {
    if (company.id)
      setStore(s => ({
        ...s,
        company,
        accountId: s.accountId || company.id,
        visualSettings: { ...s.visualSettings, logo: s.visualSettings.logo ?? company.logo },
        generalSettings: {
          ...s.generalSettings,
          companyName: s.generalSettings.companyName || company.name,
          companyDisplayName: s.generalSettings.companyDisplayName || company.name
        }
      }));
  }, [company, id]);

  const { isLoading: isNameQueryLoading } = useQuery(
    [storefrontServicesPaths.verifyName, store.generalSettings.storeName, store.accountId],
    () => verifications.names({ projectName: store.generalSettings.storeName.trim(), accountId: store.accountId }),
    {
      onSuccess: data => setNammingError(data?.available ? '' : 'The current Store name is already been used'),
      enabled:
        !!store.generalSettings.storeName &&
        store.generalSettings.storeName !== originalPage?.generalSettings.storeName &&
        !!store.accountId
    }
  );

  useEffect(() => {
    const worthIt = !!(
      originalPage?.id &&
      store?.generalSettings.companyDisplayName &&
      originalPage?.generalSettings.companyDisplayName !== store?.generalSettings.companyDisplayName
    );
    const edition = !!id;
    if (!edition || worthIt)
      setStore(s => ({
        ...s,
        generalSettings: {
          ...s.generalSettings,
          copyright: store.generalSettings.companyDisplayName
            ? `© ${new Date().getFullYear()} by ${store.generalSettings.companyDisplayName} in partnership with SwagUp`
            : '© 2022 SwagUp'
        }
      }));
  }, [store?.generalSettings.companyDisplayName]);

  const createPayloadPage = () => {
    let returnPage = store;
    returnPage = Object.keys(returnPage).reduce(
      (rslt, key) => (!skippedProperty.includes(key) ? { ...rslt, [key]: returnPage[key] } : rslt),
      {}
    );

    if (id)
      returnPage = Object.keys(returnPage).reduce(
        (rslt, key) => (returnPage[key] !== originalPage[key] ? { ...rslt, [key]: returnPage[key] } : rslt),
        {}
      );

    return returnPage;
  };

  const clanStoreProps = s => {
    let returnPage = s;
    returnPage = Object.keys(returnPage).reduce(
      (rslt, key) => (!skippedProperty.includes(key) ? { ...rslt, [key]: returnPage[key] } : rslt),
      {}
    );
    return returnPage;
  };

  const { storefrontHeaders } = useFlags();

  const maxSetps = storefrontHeaders ? 5 : 4;

  const getContinueUrl = customUrl =>
    id ? `/shop-details/${originalPage?.accessSettings.urlSlug}` : customUrl || '/shops/all-set';

  const { shopsAllowed } = useMembershipCheck();

  const { currentMembership } = useMembership();

  const { data: profile } = useProfile();

  const processStoreBeforeCreation = paramStore => ({
    ...paramStore,
    isActive: shopsAllowed,
    generalSettings: {
      ...paramStore.generalSettings,
      membershipName: currentMembership.name,
      membershipPlatformId: currentMembership.id
    },
    createdBy: profile.email
  });

  const queryClient = useQueryClient();
  const createStore = useMutation(
    params =>
      id
        ? storefronts.update(store.id, processStoreBeforeCreation(params))
        : storefronts.create(processStoreBeforeCreation(params)),
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries([storefrontServicesPaths.storefronts, company.id]);
        return history.push(getContinueUrl(), {
          infoMessage: `<a href="${getPageLink(data)}" target="_blank" rel="noreferrer">Store</a> ${
            id ? 'updated.' : 'created.'
          }`,
          store: data
        });
      },
      onError: ({ data }) => {
        if (!store?.visualSettings?.logo)
          setGeneralError('Operation failed. Please check if you added the required logo for your shop.');
        else setGeneralError(data.Messages?.find(m => m) || data.title);
      }
    }
  );

  // const { data: creditSummary } = useCreditSummary();

  const handleOnPrevious = () => {
    const futureStep = currentStep - 1;
    if (futureStep === 0) history.push(getContinueUrl('/shops'));
    else setCurrentStep(futureStep);
  };

  const handleONext = () => {
    const futureStep = currentStep + 1;
    if (futureStep > maxSetps) {
      setGeneralError();
      const storeChanges = getChangedProperties(originalPage, store);
      const updatedStore = clanStoreProps(storeChanges);
      return isEmpty(updatedStore) ? history.push(getContinueUrl()) : createStore.mutate(updatedStore);
    }
    return setCurrentStep(futureStep);
  };

  const isThemeSelected = t =>
    ['fontFamily', 'buttonStyle', 'footer', ...themeVars.map(tv => tv.key)].every(
      key => store.visualSettings[key] === t[key]
    );

  const onChange = ({ target: { value, name } }) => setStore({ ...store, [name]: value });

  const setStoreSettings = (value, propertyName = 'visualSettings') =>
    setStore(s => ({
      ...s,
      ...{
        [propertyName]: { ...s[propertyName], ...value }
      }
    }));

  // const handleDeleteProduct = prod =>
  // setStore(p => ({ ...p, productOptions: p.productOptions.filter(pr => pr.productId !== prod.productId) }));

  const handleFileUpload = async (acceptedFiles, property, additionalProperty) => {
    setArtworkLoader(al => [...al, property]);
    const image = acceptedFiles[0];
    if (!image) {
      setArtworkLoader(al => al.filter(a => a !== property));
      return true;
    }
    // const filePath = URL.createObjectURL(image);
    const uploaded = await prepareArtworksOnS3(image);
    setStore(s => {
      let aux = recursiveUpdate(property, s, uploaded.url);
      if (additionalProperty) aux = recursiveUpdate(additionalProperty, s, uploaded.url);
      return aux;
    });
    setArtworkLoader(al => al.filter(a => a !== property));
    return false;
  };

  const errors = () => {
    switch (currentStep) {
      case 1:
        if (artworkLoader.length) return 'Uploading files...';
        if (!store.generalSettings.storeName) return 'The name of the store is required';
        if (isNameQueryLoading) return 'Validating Store Name...';
        return store.generalSettings.storeName !== originalPage?.generalSettings.storeName && nammingError;
      case 2:
        if (!isHexColor(store.visualSettings.fontColor)) return themeVarsErrors.fontColor;
        if (!isHexColor(store.visualSettings.backgroundColor)) return themeVarsErrors.backgroundColor;
        if (!isHexColor(store.visualSettings.accentColor)) return themeVarsErrors.accentColor;
        return store.generalSettings.storeName !== originalPage?.generalSettings.storeName && nammingError;
      case 3:
        if (
          store.visualSettings.sections.find(sec => sec.columns.find(col => col.components.find(c => !c.value))) ||
          artworkLoader.length
        )
          return 'Some required fields are missing';
        return false;
      // case 4:
      //   return store.selectProduct && store.productOptions.length < 1 && 'You must select at least one product';
      // case 5:
      //   if (!store.confirmationPageHeadline) return 'The Confirmation Page header text is required';
      //   if (!store.confirmationPageBody) return 'The Confirmation Page subtitle is required';
      //   return false;
      case 5:
        if (isEmpty(store.generalSettings.contactEmail) || !emailRegex.test(store.generalSettings.contactEmail))
          return 'You must provide a valid contact email';
        return false;
      default:
        return false;
    }
  };
  const cantContinue = !(company?.id && !errors()) || createStore.isLoading;
  const showDisclaimer = !id && currentStep === maxSetps && !store.collectionOnly;

  return (
    <>
      <Helmet tags={seoTags.storefronts} />
      <CenteredGrid className={classes.root} style={{ paddingTop: 12 }}>
        <Grid container>
          <Grid item xs={6} style={{ paddingRight: 32 }}>
            <Grid container direction="column" className={classes.fullHeight}>
              <Grid item xs>
                <SwipeableViews axis="x" index={currentStep - 1} className={classes.swipeableViews} disabled>
                  <FormContainer
                    title="Start with the basics"
                    step={currentStep}
                    maxSetps={maxSetps}
                    showDisclaimer={showDisclaimer}
                  >
                    <PageBasics
                      store={store}
                      company={company}
                      setStore={setStore}
                      setStoreSettings={setStoreSettings}
                      handleFileUpload={handleFileUpload}
                      artworkLoader={artworkLoader}
                      onChange={onChange}
                    />
                  </FormContainer>
                  <FormContainer
                    title="Choose your theme"
                    step={currentStep}
                    maxSetps={maxSetps}
                    showDisclaimer={showDisclaimer}
                  >
                    <PageTheme
                      store={store}
                      setStore={setStore}
                      setStoreSettings={setStoreSettings}
                      isThemeSelected={isThemeSelected}
                      lightTheme={lightTheme}
                      darkTheme={darkTheme}
                      fontFamilies={fontFamilies}
                      themeVars={themeVars}
                    />
                  </FormContainer>
                  <FormContainer
                    title="Design your Shop"
                    step={currentStep}
                    maxSetps={maxSetps}
                    showDisclaimer={showDisclaimer}
                  >
                    <PageContent
                      store={store}
                      setStoreSettings={setStoreSettings}
                      templateFields={templateFields}
                      artworkLoader={artworkLoader}
                      setStore={setStore}
                      handleFileUpload={handleFileUpload}
                      onChange={onChange}
                    />
                  </FormContainer>
                  {storefrontHeaders ? (
                    <FormContainer
                      title="Define shop navigation menu"
                      step={currentStep}
                      maxSetps={maxSetps}
                      showDisclaimer={showDisclaimer}
                    >
                      <HeaderSettings store={store} setStoreSettings={setStoreSettings} />
                    </FormContainer>
                  ) : (
                    <FormContainer
                      title="Define the shop settings"
                      step={currentStep}
                      maxSetps={maxSetps}
                      showDisclaimer={showDisclaimer}
                    >
                      <StoreSettings store={store} setStoreSettings={setStoreSettings} setStore={setStore} />
                    </FormContainer>
                  )}
                  <FormContainer
                    title="Define the shop settings"
                    step={currentStep}
                    maxSetps={maxSetps}
                    showDisclaimer={showDisclaimer}
                  >
                    <StoreSettings store={store} setStoreSettings={setStoreSettings} setStore={setStore} />
                  </FormContainer>
                </SwipeableViews>
              </Grid>
              <Grid item className={classes.wizardFooter} style={{ paddingTop: showDisclaimer ? 0 : undefined }}>
                <Grid container>
                  {showDisclaimer && (
                    <Grid item xs={12}>
                      <Typography variant="body3RegularInter" className={classes.finalDisclaimer}>
                        By creating this Shop, you acknowledge and agree to our{' '}
                        <a href="https://www.swagup.com/return-refund-policy" target="_blank" rel="noreferrer">
                          Return/Refund Policy
                        </a>
                        .
                      </Typography>
                    </Grid>
                  )}
                  {generalError && (
                    <Grid item xs={12} style={{ position: 'relative' }}>
                      <Typography
                        variant="body4RegularInter"
                        className={classes.preferedImageError}
                        style={{ position: 'absolute', maxWidth: '100%', top: -18, ...ellipsisStyles }}
                      >
                        {generalError}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={4}>
                    <Button
                      size="small"
                      variant="text"
                      onClick={handleOnPrevious}
                      className={classes.previous}
                      fullWidth
                    >
                      <KeyboardBackspaceIcon className={classes.previousIcon} />
                      {currentStep === 1 ? 'Shops' : 'Previous step'}
                    </Button>
                  </Grid>
                  <Grid xs item />
                  <Grid item>
                    <CustomTooltip title={errors() || ''} arrow placement="top-start" disableHoverListener={!errors()}>
                      <div style={{ minWidth: 156 }}>
                        <Button
                          size="small"
                          variant="primary"
                          onClick={() => {
                            handleONext();
                            gtm.onClickContinue('Create Store');
                          }}
                          fullWidth
                          disabled={cantContinue}
                          loading={createStore.isLoading}
                        >
                          {currentStep === maxSetps ? `${id ? 'Update' : 'Create'}` : 'Continue'}
                        </Button>
                      </div>
                    </CustomTooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container style={{ paddingLeft: 32, height: '100%', paddingTop: 122 }}>
              <Grid item xs={12}>
                <StoreTemplates store={store} currentStep={currentStep} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CenteredGrid>
    </>
  );
};

export default StorefrontsCreate;
