import { Box, FormControl, Grid, InputAdornment, Menu, MenuItem, TextField } from '@material-ui/core';
import { Search, UnfoldMore } from '@material-ui/icons';
import { Button, Typography } from '@swagup-com/components';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useQueryParams } from '../../hooks';
import { COLOR_CONSTANT, SEARCH_DEBOUNCE_MS, defaultSortOptions } from '../../utils/constants';
import { CustomCheckIcon, FilterBy } from '../products/commonProductsElements';
import SelectFilter from '../global/SelectFilter';
import { FilterListIcon } from '../icons/footer';

const SearchSortFilter = ({ config, setIsFilterApplied, smallPadding }) => {
  const location = useLocation();
  const history = useHistory();
  const query = useQueryParams();
  const [isShowFilter, setIsShowFilter] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState(query.get(config?.search?.queryParam || 'search'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isShowFilterButton = config?.filters?.length >= 2;

  const filtersSelectedValue = queryParam =>
    query
      .get(queryParam)
      ?.split(',')
      .filter(Boolean) || [];

  const handleOnApply = React.useCallback(
    (queryParam, value) => {
      if (!value) {
        query.delete(queryParam);
      } else {
        query.set(queryParam, value);
      }
      history.replace({ ...history.location, search: query.toString() });
    },
    [history, query]
  );

  const searchTimeout = React.useRef();

  const debouncedSearch = React.useCallback(
    value => {
      if (searchTimeout.current) clearTimeout(searchTimeout);
      searchTimeout.current = setTimeout(() => {
        handleOnApply(config?.search?.queryParam || 'search', value);
      }, SEARCH_DEBOUNCE_MS);
    },
    [config?.search?.queryParam, handleOnApply]
  );

  const handleSearch = value => {
    setSearchValue(value);
    debouncedSearch(value);
  };

  React.useEffect(() => {
    if (typeof setIsFilterApplied === 'function') {
      setIsFilterApplied(isShowFilter);
    }
  }, [isShowFilter, setIsFilterApplied]);

  return (
    <Box
      display="flex"
      alignItems="center"
      gridColumnGap="20px"
      padding={smallPadding ? '20px 0px' : '32px 0px'}
      id="searchSortFilter"
    >
      <div>
        {!config?.search?.isHide && (
          <TextField
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search style={{ color: '#aaaaaa' }} />
                </InputAdornment>
              ),
              style: {
                backgroundColor: COLOR_CONSTANT.NEUTRAL_GREY,
                color: COLOR_CONSTANT.TEXT_GREY,
                fontSize: '14px',
                height: '40px',
                width: '300px'
              }
            }}
            onChange={e => {
              handleSearch(e?.target?.value);
            }}
            placeholder={config?.search?.placeholder || 'Search'}
            size="small"
            value={searchValue}
          />
        )}
      </div>
      {!config?.sort?.isHide && (
        <FormControl variant="outlined" size="small">
          <Button
            id="sortBy-button"
            aria-expanded={open ? 'true' : undefined}
            variant="body3RegularInter"
            style={{
              background: COLOR_CONSTANT.NEUTRAL_GREY,
              color: COLOR_CONSTANT.TEXT_GREY,
              fontSize: '14px',
              height: '40px',
              fontFamily: 'Inter',
              fontWeight: 400
            }}
            onClick={handleClick}
            startIcon={<UnfoldMore />}
          >
            {config?.sort?.label || 'Sort By'}
          </Button>
          <Menu id="sortBy_menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
            {Object.entries(config?.sort?.options || defaultSortOptions)?.map(([value, label], idx) => {
              const newQuery = new URLSearchParams(query);
              newQuery.set(config?.sort?.queryParam || 'ordering', value);
              return (
                <MenuItem
                  component={Link}
                  key={value}
                  to={{ ...location, search: newQuery.toString() }}
                  onClick={handleClose}
                >
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography variant="body3RegularInter" style={{ color: '#4A4F54' }}>
                        {label}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {(value === query.get(config?.sort?.queryParam || 'ordering') ||
                        (config.sortDefault &&
                          idx === 0 &&
                          isEmpty(query.get(config?.sort?.queryParam || 'ordering')))) && <CustomCheckIcon />}
                    </Grid>
                  </Grid>
                </MenuItem>
              );
            })}
          </Menu>
        </FormControl>
      )}
      {config?.filters && (
        <>
          {isShowFilterButton && (
            <Button
              variant="standard"
              style={{
                background: isShowFilter ? COLOR_CONSTANT.DARK_GREY : COLOR_CONSTANT.NEUTRAL_GREY,
                color: isShowFilter ? COLOR_CONSTANT.TEXT_WHITE : COLOR_CONSTANT.TEXT_GREY,
                fontSize: '14px',
                height: '40px',
                fontFamily: 'Inter',
                fontWeight: 400
              }}
              onClick={() => setIsShowFilter(!isShowFilter)}
              startIcon={!isShowFilter ? <FilterListIcon /> : <FilterListIcon fill="#fff" />}
            >
              Filter
            </Button>
          )}
          {(isShowFilter || !isShowFilterButton) &&
            config?.filters?.map(filter =>
              filter.isSingleSelect ? (
                <SelectFilter
                  key={filter.label}
                  customTitle={filter.label}
                  property={filter.queryParam}
                  selectOptions={filter.options || []}
                  excluded={filter.excluded}
                />
              ) : (
                <FilterBy
                  key={filter.label}
                  label={filter.label}
                  options={filter.options}
                  initialValues={filtersSelectedValue(filter?.queryParam)}
                  onApply={value => handleOnApply(filter?.queryParam, value)}
                  excluded={filter.excluded}
                />
              )
            )}
        </>
      )}
      {config?.others?.component && <div>{config.others.component}</div>}
    </Box>
  );
};

export default SearchSortFilter;
