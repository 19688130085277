const COLORS = {
  primary: '#2663FF',
  primaryHover: '#163B99',
  success: '#4CAF50',
  info: '#989EA4',
  alert: '#FF9800',
  danger: '#C62828',
  accentPurple: '#9846DD',
  white: '#ffffff',
  black: '#000000',
  textPrimary: '#131415',
  borderLineGray: '#E5E7E8',
  disabledCta: '#B7BBBF',
  dangerBackgroundColor: '#FEE8E7',
  iconBackgroundColor: '#F2F6FA'
};

export { COLORS };
