import { ellipsisStyles } from '../../shared/styles/commonStyles';

const styles = theme => ({
  container: {
    // backgroundImage: 'url(/images/dashboard/membership-promo.png)',
    borderRadius: 12,
    marginBottom: 24,
    paddingLeft: 16,
    height: 96,
    boxShadow: '0 12px 24px 0 rgba(27, 28, 31, 0.1)',
    position: 'relative',
    '&:hover': {
      boxShadow: '0 14px 26px 0 rgba(27, 28, 31, 0.1)'
    }
  },
  birdImageWrapper: {
    height: 100,
    width: 134,
    overflow: 'visible',
    position: 'relative'
  },
  birdImg: {
    objectFit: 'cover',
    height: '150%',
    width: '150%',
    position: 'absolute',
    top: -24
  },
  membershipTextOrange: {
    color: '#2663FF',
    fontFamily: 'Inter',
    fontSize: 64,
    fontWeight: 900,
    lineHeight: '72px',
    letterSpacing: '-0.72px',
    textTransform: 'uppercase',
    textShadow: '2px 2px 0 #bababa',
    '& span': {
      color: '#ffffff',
      fontStyle: 'italic',
      fontWeight: 600,
      textShadow: '2px 2px 0 #2663FF, -2px -2px 0 #2663FF, 2px -2px 0 #2663FF, -2px 2px 0 #2663FF'
    },
    [theme.breakpoints.down(1585)]: {
      fontSize: 56,
      lineHeight: '60px'
    },
    [theme.breakpoints.down(1350)]: {
      fontSize: 48,
      lineHeight: '52px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 42,
      lineHeight: '46px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 36,
      lineHeight: '38px'
    }
  },
  membershipTextWhiteUnderlineJoin: {
    color: '#1300cf',
    fontFamily: 'Inter',
    fontSize: 36,
    fontStyle: 'italic',
    fontWeight: 900,
    lineHeight: '40px',
    letterSpacing: '-0.72px',
    textTransform: 'uppercase',
    [theme.breakpoints.down(1585)]: {
      fontSize: 32,
      lineHeight: '36px'
    },
    [theme.breakpoints.down(1350)]: {
      fontSize: 30,
      lineHeight: '32px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 26,
      lineHeight: '28px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      lineHeight: '24px'
    }
  },
  membershipTextPromoDesc: {
    color: '#131415',
    fontFamily: 'Inter',
    textAlign: 'center',
    fontSize: 28,
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '-0.72px',
    textTransform: 'uppercase',
    marginBottom: 0,
    '& span': {
      color: '#2663FF'
    },
    [theme.breakpoints.down(1585)]: {
      fontSize: 24,
      lineHeight: '28px'
    },
    [theme.breakpoints.down(1350)]: {
      fontSize: 20,
      lineHeight: '24px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      lineHeight: '24px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      lineHeight: '20px'
    }
  },
  membershipTextPromoTime: {
    color: '#131415',
    fontFamily: 'Inter',
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: '-0.72px',
    textTransform: 'uppercase',
    marginBottom: 4,
    '& span': {
      color: '#2663FF'
    },
    [theme.breakpoints.down(1585)]: {
      fontSize: 20,
      lineHeight: '24px'
    },
    [theme.breakpoints.down(1350)]: {
      fontSize: 16,
      lineHeight: '18px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: '16px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      lineHeight: '16px'
    }
  },
  membershipBasicContainer: {
    padding: '0px 32px',
    [theme.breakpoints.down(1585)]: {
      padding: '0px 24px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px 16px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px'
    }
  },
  membershipPromocContainer: {
    height: 90
  },
  membershipCTA: {
    width: '100%',
    height: 32,
    maxWidth: 164,
    border: 'none',
    borderRadius: 16,
    background: '#131415',
    fontFamily: 'Inter',
    '& span': {
      color: '#ffffff'
    },
    '&:hover': {
      background: 'rgb(235, 237, 240) !important',
      '& span': {
        color: '#131415'
      }
    },
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    [theme.breakpoints.down(1585)]: {
      width: 152,
      fontSize: 15,
      height: 32,
      borderRadius: 16,
      paddingLeft: 6,
      paddingRight: 6
    },
    [theme.breakpoints.down(1350)]: {
      width: 124,
      fontSize: 14,
      height: 32,
      borderRadius: 16,
      paddingLeft: 4,
      paddingRight: 4
    },
    [theme.breakpoints.down('md')]: {
      width: 90,
      fontSize: 12,
      height: 24,
      borderRadius: 8,
      paddingLeft: 8,
      paddingRight: 8
    },
    [theme.breakpoints.down('sm')]: {
      width: 60,
      fontSize: 8,
      height: 18,
      borderRadius: 6,
      paddingLeft: 4,
      paddingRight: 4
    }
  },
  noMembershipCTA: {
    height: ' 50px',
    background: '#2663FF',
    border: 'none',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px'
  },
  closeIconButton: {
    padding: 6,
    [theme.breakpoints.down('md')]: {
      marginTop: 12
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    }
  },
  closeIcon: {
    color: '#8d9299',
    height: 18,
    width: 18
  },
  bannerImageContainerSanta: {
    height: 40,
    position: 'relative',
    [theme.breakpoints.down(1585)]: {
      width: '132px',
      height: 36
    },
    [theme.breakpoints.down('md')]: {
      height: 34
    },
    [theme.breakpoints.down('xs')]: {
      height: 32
    }
  },
  bannerImageContainer: {
    width: '164px',
    height: 72,
    position: 'relative',
    [theme.breakpoints.down(1585)]: {
      width: '132px'
    },
    [theme.breakpoints.down('md')]: {
      width: '124px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '112px'
    }
  },
  bannerImage: {
    width: '100%',
    height: `100%`,
    objectFit: 'contain'
  },
  bannerImageSanta: {
    objectFit: 'contain',
    width: '100%',
    height: `100%`,
    position: 'absolute',
    height: 44,
    top: -2,
    left: 4,
    [theme.breakpoints.down(1585)]: {
      height: 38
    },
    [theme.breakpoints.down('md')]: {
      height: 34
    },
    [theme.breakpoints.down('xs')]: {
      height: 32
    }
  },
  bannerStar: {
    position: 'absolute'
  },
  middleStarts: {
    [theme.breakpoints.down(1350)]: {
      display: 'none'
    }
  }
});

export default styles;
