import * as React from 'react';
import { FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Img } from '../../../global/ImgUtils';
import ContactForm from '../../../pages/orders/requested/shipments/ContactForm';
import { greyRoundBorder } from '../../../shared/styles/commonStyles';
import Drawer from '../../../pages/orders/requested/common/Drawer';
import { CustomTextField, FileUploadZone } from '../../storeCommon';
import { emailRegex } from '../../../global/Forms/commonValidations';
import Tooltip from '../../../shared/Tooltip';
import { NAME_RE } from '../../../../utils/regexs';
import useMembershipCheck from '../../../../hooks/useMembershipCheck';

const text = {
  fontFamily: 'Gilroy-Medium',
  letterSpacing: 'normal'
};

const useCSVStyles = makeStyles({
  csvSection: {
    ...greyRoundBorder,
    padding: '22px 25px'
  },
  primaryText: {
    ...text,
    fontSize: 14,
    color: '#0b1829'
  },
  secondaryText: {
    ...text,
    fontSize: 12,
    color: '#787b80'
  },
  uploadCSVLink: {
    height: 'unset',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    letterSpacing: 0
  },
  separator: {
    margin: '32px 0px 8px 0px',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      margin: '0 18px'
    },
    '&:before, &:after': {
      content: "''",
      display: 'flex',
      flex: 1,
      borderTop: '1px solid #f0f2f5'
    }
  }
});

const ImportFromCSV = ({ handleFileUpload, customMessage }) => {
  const classes = useCSVStyles();
  return (
    <>
      <Grid container alignItems="center" className={classes.csvSection}>
        <Grid item style={{ marginRight: 16, lineHeight: 0 }}>
          <Img src="/images/addContact/csv-icon-48.png" alt="csv-icon" width={48} height={48} />
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <p className={classes.primaryText}>Upload multiple customers at once</p>
          <p className={classes.secondaryText} style={{ marginTop: 6 }}>
            Use CSV upload feature
          </p>
        </Grid>
        <Grid item>
          <FileUploadZone accept={{ 'text/csv': ['.csv'] }} handleFileUpload={handleFileUpload}>
            <Tooltip
              title={
                customMessage ||
                'The column headers for CSV file need to be in following order: first_name, last_name, email, credits. Credits are optional'
              }
            >
              <Button variant="text" className={classes.uploadCSVLink}>
                Upload CSV
              </Button>
            </Tooltip>
          </FileUploadZone>
        </Grid>
      </Grid>

      <p className={clsx(classes.separator, classes.secondaryText)}>
        <span>or</span>
      </p>
    </>
  );
};

const useCustomerStyles = makeStyles({
  root: { zIndex: '1300 !important' },
  paper: {
    maxWidth: 594,
    padding: '35px 20px 35px 45px'
  },
  formControl: {
    width: '100%',
    alignItems: 'flex-start',
    marginLeft: '0px',
    '& .MuiFormControlLabel-root': {
      alignItems: 'flex-start'
    }
  },
  inputLabel: {
    textAlign: 'left'
  }
});

function readCsvFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Define onload event handler
    reader.onload = event => {
      const csvString = event.target.result;
      resolve(csvString);
    };

    // Define onerror event handler
    reader.onerror = error => {
      reject(error);
    };

    // Read the file as text
    reader.readAsText(file);
  });
}

// Example usage

const parseCsv = (csvData, setError, shopCreditsAllowed) => {
  let error = '';
  const entities = [];
  const lines = csvData.split('\n');

  // Validate CSV headers
  const headers = lines[0]
    .replace('\r', '')
    .replace('\n', '')
    .split(',');

  if (headers.length === 3) {
    if (headers[0] !== 'first_name' || headers[1] !== 'last_name' || headers[2] !== 'email') {
      error = 'Invalid CSV headers. Expected format: "first_name,last_name,email"\n';
    }

    // Parse each line of CSV data
    for (let i = 1; i < lines.length; i++) {
      const line = lines[i].trim();
      if (line === '') continue; // Skip empty lines

      const fields = line.split(',');
      if (fields.length !== 3) {
        error += `Invalid data at line ${i + 1}. Expected format: "first_name,last_name,email"\n`;
      }

      const [first_name, last_name, email] = fields;

      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        error += `Invalid email format at line ${i + 1}\n`;
      }

      // Create entity object
      const entity = {
        first_name: first_name.trim(),
        last_name: last_name.trim(),
        email: email.trim()
      };

      entities.push(entity);
    }
  }
  if (headers.length === 4) {
    if (
      headers[0] !== 'first_name' ||
      headers[1] !== 'last_name' ||
      headers[2] !== 'email' ||
      headers[3] !== 'credits'
    ) {
      error = 'Invalid CSV headers. Expected format: "first_name,last_name,email,credits"\n';
    }

    // Parse each line of CSV data
    for (let i = 1; i < lines.length; i++) {
      const line = lines[i].trim();
      if (line === '') continue; // Skip empty lines

      const fields = line.split(',');
      if (fields.length !== 4) {
        error += `Invalid data at line ${i + 1}. Expected format: "first_name,last_name,email,credits"\n`;
      }

      const [first_name, last_name, email, credits] = fields;

      // Validate first_name format
      if (!NAME_RE.test(first_name)) {
        error += `Invalid first_name format at line ${i + 1}\n`;
      }

      // Validate last_name format
      if (!NAME_RE.test(last_name)) {
        error += `Invalid last_name format at line ${i + 1}\n`;
      }

      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        error += `Invalid email format at line ${i + 1}\n`;
      }

      // Validate credits format
      const creditsRegex = /-?\d+(\.\d{1,2})?/;
      if (shopCreditsAllowed && !creditsRegex.test(credits)) {
        error += `Invalid credtis format at line ${i + 1}\n`;
      }

      // Create entity object
      const entity = {
        first_name: first_name.trim(),
        last_name: last_name.trim(),
        email: email.trim(),
        amount: shopCreditsAllowed ? parseFloat(credits) : 0
      };
      entities.push(entity);
    }
  }
  setError(error);
  return entities;
};

const NewCustomerForm = ({ onAdd, setCsvImportError, entity, customMessage }) => {
  const [customerInfo, setCustomerInfo] = useState({});
  const [error, setError] = useState(true);
  const [isTouched, setIsTouched] = useState(false);
  const [csvUploadError, setCsvUploadError] = useState();

  const membershipCheck = useMembershipCheck();

  const handleOnFieldChange = event => {
    const {
      target: { name, value }
    } = event;
    setCustomerInfo(prev => ({ ...prev, [name]: value?.trim() }));
    setIsTouched(true);
  };

  const handleFileUpload = async value => {
    setCsvUploadError();
    const csvString = await readCsvFile(value[0]);
    const newCustomerList = parseCsv(csvString, setCsvUploadError, membershipCheck.shopCreditsAllowed);
    onAdd(newCustomerList, 'add_csv');
  };

  useEffect(() => {
    setCsvImportError(csvUploadError);
  }, [csvUploadError]);

  useEffect(() => {
    if (isTouched) {
      let customError =
        isEmpty(customerInfo.firsName) || isEmpty(customerInfo.firsName)
          ? 'You must define a first or last name'
          : undefined;
      customError = isEmpty(customerInfo.email) ? 'You must define an email' : undefined;
      customError = !emailRegex.test(customerInfo.email) ? 'The email must be valid' : undefined;
      setError(customError);
    }
  }, [customerInfo, isTouched]);

  const classes = useCustomerStyles();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4SemiBoldInter">Add a new {entity || 'customer'}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ImportFromCSV handleFileUpload={handleFileUpload} customMessage={customMessage} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body3MediumInter">Quick {entity || 'Customer'} Info</Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <FormControlLabel
              className={classes.formControl}
              labelPlacement="top"
              control={
                <CustomTextField
                  className={classes.inputText}
                  first_name="First Name"
                  placeholder="First Name"
                  name="first_name"
                  onChange={handleOnFieldChange}
                  fullWidth
                />
              }
              label={
                <Typography variant="body4RegularInter" style={{ marginBottom: 8 }}>
                  First Name
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              className={classes.formControl}
              labelPlacement="top"
              control={
                <CustomTextField
                  className={classes.inputText}
                  placeholder="Last Name"
                  name="last_name"
                  onChange={handleOnFieldChange}
                  fullWidth
                />
              }
              label={
                <Typography variant="body4RegularInter" style={{ marginBottom: 8 }}>
                  Last Name
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 8 }}>
            <FormControlLabel
              className={classes.formControl}
              labelPlacement="top"
              control={
                <CustomTextField
                  className={classes.inputText}
                  placeholder="Email"
                  name="email"
                  onChange={handleOnFieldChange}
                  fullWidth
                />
              }
              label={
                <Typography variant="body4RegularInter" style={{ marginBottom: 8 }}>
                  Email
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" style={{ marginTop: 32 }}>
          <Grid item>
            <Button
              size="small"
              variant="primary"
              style={{ height: entity ? '48px' : undefined }}
              disabled={!!error}
              onClick={() => onAdd([customerInfo], 'add_new')}
            >
              Add {entity || 'Customer'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  root: { zIndex: '1300 !important' },
  paper: {
    maxWidth: 594,
    padding: '35px 20px 35px 45px'
  }
});

const AddNewCustomer = ({ open, onClose, onSuccess, setCsvImportError, entity, customMessage }) => {
  const classes = useStyles();
  return (
    <Drawer open={open} onClose={onClose} classes={classes}>
      <NewCustomerForm
        onAdd={onSuccess}
        setCsvImportError={setCsvImportError}
        entity={entity}
        customMessage={customMessage}
      />
    </Drawer>
  );
};

export default AddNewCustomer;
