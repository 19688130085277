import React, { useEffect, useMemo, useState } from 'react';
import { Grid, makeStyles, Table, TableBody, TableContainer, Snackbar } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Button, Typography } from '@swagup-com/components';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import {
  SaveAlt,
  BlockOutlined,
  Block,
  Replay,
  Delete,
  DeleteOutline,
  ReplayOutlined,
  CancelRounded as CancelIcon,
  CheckCircle as CheckIcon,
  Add,
  EmailOutlined,
  Email
} from '@material-ui/icons';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import min from 'lodash/min';

import ShipmentAlert from '@material-ui/lab/Alert';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Alert, CenteredGrid, Helmet, Pagination } from '../shared';
import styles from './styles/redeemPagesHome';
import {
  RedemptionRow,
  TableEmptyState,
  PageRedemptionsBlock,
  PageInventoryBlock,
  AvailableCreditBlock,
  ShareStatusBlock,
  MIN_CREDIT_BALANCE,
  TableHeaderAutoShip,
  RedemptionDeleteModal,
  getPageLink,
  RedemptionQuestionnaire,
  PageHistoryBlock,
  buildRecipientPayload
} from './redeemCommon';
import { useCreditSummary, usePaginatedQuery, useQueryParams } from '../../hooks';
import Loader from '../global/Loader';
import { redeemPages, redemptions } from '../../apis/redeemServices';
import redeemServicesPaths from '../../helpers/redeemServicesPaths';
import { downloadData, imageSrcSet, moneyStr, paginationRequestConverter } from '../../helpers/utils';
import { StylessButton } from '../buttons';
import seoTags from '../../apis/seoTags';
import RedeemFilterSection from './RedeemFilterSection';
import ProductSelectedDetails from './ProductSelectedDetails';
import { useQueryFilterValidated } from '../../hooks/useFilters';
import { sizes } from '../../__factory__';
import usePageProductBasedInfo from './hooks/usePageProductBasedInfo';
import CreditDrawer from './components/CreditCardDrawer/CreditDrawer';
import ActionBar from './components/ActionBar/ActionBar';
import { CustomTooltip } from '../products/commonProductsElements';
import apiPaths from '../../helpers/apiPaths';
// import { shipmentsApi } from '../../apis/swagup';
import SpinningSnackBar from './components/SpinningSnackBar/SpinningSnackBar';
import ShipmentDetailsModal from './ShipmentDetailsModal';
import RedeemPageShareModal from './RedeemPageShareModal';
import EditField from '../global/EditField';
import SearchSortFilter from '../shared/SearchSortFilter';
import IconLabel from '../global/IconLabel';
import { DownloadIcon } from '../icons';
import AddContact from '../pages/orders/requested/shipments/AddContact';
import SelectFromExistingDrawer from '../storefronts/components/SelectFromExistingDrawer/SelectFromExistingDrawer';
import SelectFromContactsDrawer from './components/SelectFromContactsDrawer/SelectFromContactsDrawer';
import AddNewCustomer from '../storefronts/components/CreateNewCustomer/AddNewCustomer';
import ExistingRecipientsModal from './components/ExistingRecipientsModal/ExistingRecipientsModal';

const useStyles = makeStyles(styles);

const perPageOptions = [10, 20, 30, 40];

const sortSizes = summarySizes =>
  summarySizes
    .map(s => {
      const defaulSize = sizes.results.find(si => si.name === s.size);
      return { ...s, category: defaulSize?.category, idx: defaulSize?.name === 'XXS' ? 0 : defaulSize?.id };
    })
    .sort((sA, sB) => (sA.category > sB.category || sA.idx > sB.idx ? 1 : -1));

const sortSummarySizes = summary => ({
  ...summary,
  selectedProducts: summary.selectedProducts.map(sp => ({ ...sp, sizes: sortSizes(sp.sizes) })),
  totalSizes: sortSizes(summary.totalSizes)
});

const shipmentAPIStatuses = [
  'Scheduled',
  'On Its Way',
  'Delivered',
  'Failure',
  'Return To Sender',
  'Invalid Address',
  'Cancelled',
  'Pending Production',
  'Out Of Stock',
  'Insufficient Credit',
  'Unexpected Error'
];

const redeemStatuses = ['Redeemed', 'Unredeemed'];

const getCustomFilter = (summary, collectionOnly, uniqueUrl) => {
  let statuses = [];
  switch (true) {
    case !collectionOnly && uniqueUrl:
      statuses = ['Unredeemed', ...shipmentAPIStatuses];
      break;
    case !collectionOnly && !uniqueUrl:
      statuses = shipmentAPIStatuses;
      break;
    case collectionOnly && uniqueUrl:
      statuses = redeemStatuses;
      break;
    default:
      statuses = ['Redeemed'];
  }
  return summary
    ? [
        {
          name: 'shipment_status',
          label: 'Status',
          behavior: 'multiselect',
          options: statuses.reduce((sum, s) => ({ ...sum, [s]: s }), {})
        },
        {
          name: 'item',
          label: 'Product Selected',
          behavior: 'multiselect',
          options: summary.selectedProducts.reduce((sum, p) => ({ ...sum, [p.productId]: p.name }), {})
        }
      ].filter(f => !isEmpty(f.options))
    : undefined;
};

const RedeemPageHistory = () => {
  const [page, setPage] = useState({});
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [openProductDrawer, setOpenProductDrawer] = useState(false);
  const [showInfoMessage, setShowInfoMessage] = useState(true);
  const [openCreditDrawer, setOpenCreditDrawer] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [shipmentDetailsId, setShipmentDetailsId] = useState(0);
  const [redemptionToDelete, setRedemptionToDelete] = useState();
  const [redemptionQuestionnaire, setRedemptionQuestionnaire] = useState();
  const [wasSuccessful, setWasSuccessful] = useState({ was: false, what: 'scheduled', who: 'Shipment' });
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [multipleRetries, setMultipleRetries] = useState(0);
  const [selectedShipmentStatus, setSelectedShipmentStatus] = useState([]);
  const [customProcessingMessage, setCustomProcessingMessage] = useState();
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isAddDrawerOpen, setIsAddDrawerOpen] = useState(false);
  const [openRecipientDrawer, setOpenRecipientDrawer] = useState(false);
  const [isAddNewCustomerOpen, setIsAddNewCustomerOpen] = useState(false);
  const [openExistingRecipientsModal, setOpenExistingRecipientsModal] = useState(false);
  const [csvImportError, setCsvImportError] = useState();
  const [contactsToAdd, setContactsToAdd] = useState([]);
  const [uploadStatus, setUploadStatus] = useState();
  const [sendingCase, setSendingCase] = useState();

  const classes = useStyles();

  const { id } = useParams();

  const history = useHistory();
  const location = useLocation();
  const { infoMessage } = location.state || {};

  const company = useSelector(state => state.company);

  const { data: redeemPageQuery, isLoading } = useQuery(redeemServicesPaths.redeemPage(id), () => redeemPages.get(id), {
    enabled: !!id
  });

  const { data: summaryCall, isLoadingSummary } = useQuery(
    redeemServicesPaths.summary(page?.id),
    () => redeemPages.summary(page?.id),
    {
      enabled: !!page?.id
    }
  );
  const summaryData = summaryCall?.data;
  const summary = summaryData ? sortSummarySizes(summaryData) : summaryData;
  const customFilters = getCustomFilter(summary, page.collectionOnly, page.uniqueUrl);
  useEffect(() => {
    if (redeemPageQuery?.id) setPage(redeemPageQuery);
  }, [redeemPageQuery]);

  const query = useQueryParams();

  const search = query.get('search') || '';
  const isSearching = !!search;
  const item = useQueryFilterValidated('item');
  const size = useQueryFilterValidated('size', val =>
    ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', 'One Size', 'XXS'].includes(val)
  );
  const shipmentStatus = useQueryFilterValidated('shipment_status', val =>
    [...redeemStatuses, ...shipmentAPIStatuses].includes(val)
  );

  const ordering = useQueryFilterValidated(
    'ordering',
    (xid, value) => ['-created_at', 'created_at'].includes(xid) && value.split(',').length === 1,
    false,
    '-created_at'
  );

  const {
    query: { data: redemptionsResults, isFetching: loadingHistory },
    pagination
  } = usePaginatedQuery({
    queryKey: [redeemServicesPaths.redemptions(page.id), search, item, size, ordering, shipmentStatus],
    queryFn: (limit, offset) => {
      return page.id
        ? redemptions.list(
            page.id,
            paginationRequestConverter({ limit, offset, search, item, size, ordering, shipmentStatus })
          )
        : [];
    },
    perPageOptions
  });

  const redemptionItems = redemptionsResults?.results || [];
  const queryClient = useQueryClient();

  const contactExport = useMutation(
    customQuery => redeemPages.export(page.id, customQuery || { search, item, size, shipmentStatus }),
    {
      onSuccess: ({ data }) => {
        downloadData(`${page.projectName}.csv`, data);
      }
    }
  );

  const updateRedeem = useMutation(changes => redeemPages.update(page.id, changes), {
    onSuccess: (req, param) => {
      queryClient.invalidateQueries([redeemServicesPaths.redeemPages, company.id]);
      queryClient.invalidateQueries([redeemServicesPaths.redemptions(page.id)]);
      setPage(prevPage => ({ ...prevPage, ...param }));
      setCustomProcessingMessage();
      if (param.uniqueUrl && param.uniqueUrlNumber > 0) {
        setWasSuccessful({ was: true, what: 'created', who: `${param.uniqueUrlNumber} unique` });
        setShowSnackbar(true);
      }
    }
  });
  const { data: creditSummary } = useCreditSummary();
  const { selectedProducts, recommendedCredits } = usePageProductBasedInfo(page);
  const exportContact = e => {
    e.stopPropagation();
    contactExport.mutate();
  };

  const exportUnredeemedContact = e => {
    e.stopPropagation();
    contactExport.mutate({ shipmentStatus: 'Unredeemed' });
  };

  const showPagination = pagination?.count > min(pagination?.sizeOptions);

  const handleClose = () => {
    setShowInfoMessage(false);
    history.replace({ ...location, state: { ...location.state, infoMessage: undefined } });
  };

  const toogleSelectedItems = sitem =>
    setSelectedItems(prev =>
      prev.find(it => it.id === sitem.id) ? prev.filter(it => it.id !== sitem.id) : [...prev, sitem]
    );

  const onReload = (snack = true) => {
    queryClient.invalidateQueries(apiPaths.creditsSummaries);
    queryClient.invalidateQueries([redeemServicesPaths.redemptions(page.id)]);
    queryClient.invalidateQueries([redeemServicesPaths.summary(page?.id)]);
    const accountProductsParams = {
      ids: page.productOptions?.map(p => p.productId).join()
    };
    queryClient.invalidateQueries([apiPaths.accountProducts, accountProductsParams]);

    setSelectedItems([]);
    setShowSnackbar(snack);
  };

  const deletePageRedemption = useMutation(
    redemptionIdList => redemptions.delete(page.id, { redemptions: redemptionIdList }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([redeemServicesPaths.redemptions(page.id)]);
        if (page.collectionOnly) queryClient.invalidateQueries([redeemServicesPaths.summary(page?.id)]);
        setSelectedItems([]);
      }
    }
  );

  const cancelAPIShipments = useMutation(
    redemptionIdList => redemptions.cancel(page.id, { redemptions: redemptionIdList }),
    {
      onSuccess: res => {
        onReload();
        if (res?.failedRedemptions?.length > 0) {
          setSelectedShipmentStatus(res.failedRedemptions);
          setWasSuccessful({ was: false, what: 'canceled', who: 'Shipment' });
        } else {
          setWasSuccessful({ was: true, what: 'canceled', who: 'Shipment' });
        }
      }
    }
  );

  const retryRedeemShipment = useMutation(
    redemptionIdList => redemptions.retry(page.id, { redemptions: redemptionIdList }),
    {
      onSuccess: res => {
        onReload();
        if (res?.failedRedemptions?.length > 0) {
          setSelectedShipmentStatus(res.failedRedemptions);
          setWasSuccessful({ was: false, what: 'scheduled', who: 'Shipment' });
        } else {
          setWasSuccessful({ was: true, what: 'scheduled', who: 'Shipment' });
        }
      }
    }
  );

  const retryShipments = redemptionIdList => {
    setMultipleRetries(redemptionIdList.length);
    retryRedeemShipment.mutate(redemptionIdList);
  };

  const deleteRedemptions = redemptionIdList => {
    deletePageRedemption.mutate(redemptionIdList);
  };

  const cancelShipments = shipmentList => {
    cancelAPIShipments.mutate(shipmentList);
  };

  const showInventoryDrawer = () => {
    setShowSnackbar(false);
    setOpenProductDrawer(true);
  };

  const closeErrorMessageSnackbar = () => {
    setSelectedShipmentStatus([]);
    setShowSnackbar(false);
  };

  const showCreditReloadDrawer = () => {
    setShowSnackbar(false);
    setOpenCreditDrawer(true);
  };

  const redirectHelpCenter = () => {
    setShowSnackbar(false);
    window.open('https://support.swagup.com/en', '_blank');
  };

  const retryShipment = r => {
    if (r) return retryShipments([r.id]);
    const redemptionIdList = selectedItems.map(s => s.id);
    return retryShipments(redemptionIdList);
  };

  const reSendEmail = r => {
    setSendingCase('resend');

    if (!isEmpty(r)) setSelectedItems([r]);

    setOpenExistingRecipientsModal(true);
  };

  const onDeleteRedemption = r => {
    setRedemptionToDelete(r);
  };

  const onQuestionnaireAnswers = r => {
    setRedemptionQuestionnaire(r);
  };

  const onDeleteRedemptionClose = () => {
    setRedemptionToDelete();
  };

  const onRedemptionQuestionnaireClose = () => {
    setRedemptionQuestionnaire();
  };

  const deleteRedemption = r => {
    onDeleteRedemptionClose();
    if (r && !_.isArray(r)) return deleteRedemptions([r.id]);
    const redemptionIdList = selectedItems.map(s => s.id);
    return deleteRedemptions(redemptionIdList);
  };

  const cancelShipment = r => {
    if (r) return cancelShipments([r.id]);
    const redemptionIdList = selectedItems.map(s => s.id);
    return cancelShipments(redemptionIdList);
  };

  useEffect(() => {
    queryClient.invalidateQueries(apiPaths.creditsSummaries);
    queryClient.invalidateQueries([redeemServicesPaths.redemptions(page.id)]);
  }, [page.id, queryClient]);

  const toogleSelectAll = () =>
    setSelectedItems(prev => (prev.length !== redemptionItems.length ? redemptionItems.map(r => r) : []));

  const onSave = changes => {
    const properties = ['isActive', 'uniqueUrl', 'uniqueUrlNumber', 'allowMultipleRedemptions', 'emailRestriction'];
    const latestChanges = properties.reduce(
      (acc, property) =>
        !_.isUndefined(changes[property]) && changes[property] !== page[property]
          ? { ...acc, [property]: changes[property] }
          : acc,
      {}
    );
    if (_.isEmpty(latestChanges) && !changes.uniqueUrlNumber) return;

    if (latestChanges.uniqueUrl || changes.uniqueUrlNumber) {
      if (changes.uniqueUrlNumber > 0) setCustomProcessingMessage(`Generating ${changes.uniqueUrlNumber} unique links`);
      setShareModalOpen(false);
    }
    updateRedeem.mutate(latestChanges);
  };

  const handleOnAddNewCustomersFromRecipient = (contacts, actionType) => {
    const payload = buildRecipientPayload(contacts);
    console.log('handleOnAddNewCustomersFromRecipient: ', payload, actionType);
    setUploadStatus(actionType);
    setContactsToAdd(payload);
    setOpenExistingRecipientsModal(true);
    // // here
    // if (contacts.every(c => isNumber(c.amount))) {
    //   setOpenExistingRecipientsModal(true);
    // } else {
    //   setOpenAddCustomersModal(true);
    // }

    setIsAddNewCustomerOpen(false);
  };

  const triggerCopyNotifications = text => {
    setShowSnackbar(true);
    setWasSuccessful(prev => ({ ...prev, was: true, custom: text }));
  };

  const getProcessingMessage = () => {
    switch (true) {
      case cancelAPIShipments.isLoading:
        return `Canceling your shipment${selectedItems.length > 0 ? 's' : ''}`;
      case retryRedeemShipment.isLoading:
        return multipleRetries === 1 ? 'Scheduling your shipment' : `Scheduling ${multipleRetries} shipments`;
      case deletePageRedemption.isLoading:
        return 'Deleting...';
      case isSearching:
        return 'Searching...';
      case !!customProcessingMessage:
        return customProcessingMessage;
      default:
        return 'Loading...';
    }
  };

  const getFailedRedemptionMessage = () => {
    switch (selectedShipmentStatus.length && selectedShipmentStatus[0].failedRedemptionStatus) {
      case 'Unexpected Error':
        return (
          <>
            Shipment failed. Contact{' '}
            <button type="button" onClick={() => redirectHelpCenter()} className={classes.drawerLink}>
              customer support
            </button>
            .
          </>
        );
      case 'Out Of Stock':
        return (
          <>
            Hmm, looks like you’re missing some{' '}
            <button type="button" onClick={() => showInventoryDrawer()} className={classes.drawerLink}>
              inventory
            </button>
            .
          </>
        );
      case 'Insufficient Credit':
        return (
          <>
            Oops, looks like you’re low on{' '}
            <button type="button" onClick={() => showCreditReloadDrawer()} className={classes.drawerLink}>
              credit
            </button>
            .
          </>
        );
      case 'Product cannot be shipped internationally':
        return <>This product can't ship internationally. Please contact support.</>;
      default:
        return null;
    }
  };

  const generateUniqueUrl = uniqueUrlId => `${getPageLink(page)}/${uniqueUrlId || ''}`;

  const regularMessaging = () =>
    multipleRetries === 1 ? getFailedRedemptionMessage() : 'Please address the following';

  const { leftBarNavigation } = useFlags();

  const { leftNavOpen } = useSelector(state => state.commonReducer);

  const navbarStyles = useMemo(() => {
    return leftNavOpen
      ? { width: 'calc(calc(100% - 300px))', marginLeft: '260px' }
      : { marginLeft: '110px', width: 'calc(100% - 150px)' };
  }, [leftNavOpen]);

  const searchSortFilterConfig = {
    search: { placeholder: 'Search' },
    sort: { options: { '-created_at': 'Most Recent', created_at: 'Less Recent' } },
    filters: [
      { label: 'Status', queryParam: 'shipment_status', options: customFilters && customFilters[0]?.options },
      {
        label: 'Product Selected',
        queryParam: 'item',
        options: (customFilters && customFilters[1]?.options) || []
      }
    ]
    // others: {
    //   component: (
    //     <IconLabel
    //       icon={<DownloadIcon fill="#125CFF" />}
    //       label="Export CSV"
    //       handleClick={exportContact}
    //       isHideLabel={!isFilterApplied}
    //     />
    //   )
    // }
  };

  return (
    <>
      <Helmet tags={seoTags.redeem} />
      <CenteredGrid>
        <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: 24, marginTop: 18 }}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item>
                    <Link to="/redeem-pages">
                      <Typography variant="body4RegularInter" className={classes.goBack}>
                        <ArrowBackIcon className={classes.goBackIcon} />
                        Back to Redeem Pages
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs style={{ paddingLeft: 4 }}>
                    / {page.customProjectName || page.projectName}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                {infoMessage && showInfoMessage && (
                  <Grid container justifyContent="center" style={{ position: 'relative' }}>
                    <Alert
                      onClose={handleClose}
                      delayTime={10000}
                      className={classes.floatingInfoMessage}
                      fontStyles={{ fontSize: 12, padding: 0 }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: infoMessage }} />
                    </Alert>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={4} />
            </Grid>
          </Grid>

          <Grid item xs>
            <EditField
              value={page.customProjectName || page.projectName}
              className={classes.redeemPageDetailsName}
              onEdit={name => updateRedeem.mutate({ customProjectName: name })}
              isLoading={updateRedeem.isLoading}
            />
          </Grid>
          <Grid item style={{ paddingRight: 16 }}>
            <Button
              variant="text"
              href={`${getPageLink(page)}/preview`}
              style={{ minWidth: 110, height: 56 }}
              target="_blank"
              rel="noreferrer"
              className={classes.previewBtn}
            >
              <Typography variant="buttonMediumInter" style={{ color: '#3577D4' }}>
                Preview
              </Typography>
              <span style={{ paddingLeft: 8 }}>
                <img
                  alt="Store-Front-Preview"
                  src="/images/storefront/store-front-preview.png"
                  srcSet={imageSrcSet('/images/storefront/store-front-preview.png')}
                  width={24}
                  height={24}
                />
              </span>
            </Button>
          </Grid>
          <Grid item style={{ paddingRight: 16 }}>
            <Button
              variant="text"
              component={Link}
              to={`/redeem-details/${page.urlSlug}`}
              style={{ minWidth: 110, height: 56 }}
            >
              <Typography variant="buttonMediumInter" style={{ color: '#3577D4' }}>
                Customize
              </Typography>
              <span style={{ paddingLeft: 8 }}>
                <EditOutlinedIcon />
              </span>
            </Button>
          </Grid>
          <Grid item>
            <CustomTooltip
              title="A min. credit balance of $100 is required to activate an Autoship redeem page"
              arrow
              placement="top-start"
              disableHoverListener={
                page.collectionOnly || page.isActive || creditSummary.current_balance >= MIN_CREDIT_BALANCE
              }
            >
              <Button
                variant="text"
                onClick={() => setShareModalOpen(true)}
                style={{ minWidth: 110, height: 56 }}
                disabled={!page.collectionOnly && !page.isActive && creditSummary.current_balance < MIN_CREDIT_BALANCE}
              >
                <Typography variant="buttonMediumInter" style={{ color: '#3577D4' }}>
                  Share
                </Typography>
                <span style={{ paddingLeft: 8, paddingBottom: 4 }}>
                  <img
                    alt="Store-Front-Share"
                    src="/images/storefront/share.png"
                    srcSet={imageSrcSet('/images/storefront/share.png')}
                    width={16}
                    height={20}
                  />
                </span>
              </Button>
            </CustomTooltip>
          </Grid>
        </Grid>
      </CenteredGrid>

      <div style={{ borderTop: '1px solid #E5E7E8', paddingTop: '12px', ...(leftBarNavigation ? navbarStyles : {}) }}>
        <CenteredGrid>
          <Grid container spacing={6}>
            <Grid item xs={2}>
              <ShareStatusBlock isActive={page.isActive} classes={classes} />
            </Grid>
            <Grid item>
              <PageRedemptionsBlock totalRedemptions={summary?.totalRedemptions} classes={classes} />
            </Grid>
            <Grid item>
              <PageHistoryBlock title="Total Count" value={redemptionsResults?.count || 0} classes={classes} />
            </Grid>
            <Grid item xs={2}>
              <PageHistoryBlock
                title="Total Shipping Cost"
                value={moneyStr(summary?.totalShipment)}
                classes={classes}
              />
            </Grid>
            <Grid item xs>
              <PageInventoryBlock
                totalProducts={page.productOptions?.length}
                onViewDetails={() => setOpenProductDrawer(true)}
                classes={classes}
              />
            </Grid>
            <Grid item xs>
              <AvailableCreditBlock
                creditBalance={creditSummary.current_balance}
                onReload={() => setOpenCreditDrawer(true)}
                classes={classes}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs>
              <SearchSortFilter config={searchSortFilterConfig} setIsFilterApplied={setIsFilterApplied} />
            </Grid>
            <Grid item>
              <IconLabel
                icon={<DownloadIcon fill="#125CFF" />}
                label="Export CSV"
                handleClick={exportContact}
                isHideLabel
                style={{ marginTop: 6, marginRight: 24 }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="primary"
                size="small"
                style={{ height: 38, backgroundColor: '#125CFF' }}
                onClick={() => setOpenRecipientDrawer(true)}
              >
                Add Recipient <Add style={{ height: 16, width: 16, marginTop: 0, marginLeft: 6 }} />
              </Button>
            </Grid>
          </Grid>
        </CenteredGrid>
      </div>

      <Snackbar
        className={classes.snackbarContainer}
        open={showSnackbar}
        autoHideDuration={wasSuccessful.was ? 3000 : null}
        onClose={() => {
          setShowSnackbar(false);
          if (!wasSuccessful.was) closeErrorMessageSnackbar();
        }}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      >
        {wasSuccessful.was ? (
          <ShipmentAlert
            className={classes.successShipmentAlert}
            icon={<CheckIcon style={{ color: '#2E7D32', fontSize: '20px' }} />}
            variant="outlined"
            severity="success"
          >
            {wasSuccessful.custom ? wasSuccessful.custom : `${wasSuccessful.who} successfully ${wasSuccessful.what}`}
          </ShipmentAlert>
        ) : (
          <ShipmentAlert
            onClose={() => {
              closeErrorMessageSnackbar();
            }}
            className={classes.failureShipmentAlert}
            icon={<CancelIcon style={{ color: '#C62828', fontSize: '20px' }} />}
            variant="outlined"
            severity="error"
          >
            {wasSuccessful.what === 'canceled'
              ? `Cancelation${multipleRetries > 1 ? '' : 's'} failed`
              : regularMessaging()}
          </ShipmentAlert>
        )}
      </Snackbar>

      <CenteredGrid style={leftBarNavigation ? { ...navbarStyles, marginLeft: leftNavOpen ? '250px' : '105px' } : {}}>
        <div className={page.collectionOnly ? classes.tableWrapper : classes.tableWrapperSmall}>
          <div className={classes.tableContainer} style={{ height: `calc(100% - ${showPagination ? 42 : 0}px)` }}>
            {redemptionItems?.length === 0 && !loadingHistory ? (
              <TableEmptyState isSearching={isSearching} imageSrc="/images/redeem/empty-table.svg" />
            ) : (
              <TableContainer>
                <Table stickyHeader className={classes.redemptionTable}>
                  <TableHeaderAutoShip
                    classes={classes}
                    toogleSelectAll={toogleSelectAll}
                    allSelected={redemptionItems.length === selectedItems.length}
                    isCollection={page.collectionOnly}
                  />
                  <TableBody>
                    {redemptionItems?.map(redemption => (
                      <RedemptionRow
                        key={redemption.id}
                        redemption={redemption}
                        customAction={() => setOpenProductDrawer(true)}
                        isCollection={page.collectionOnly}
                        toogleSelectedItems={toogleSelectedItems}
                        onOpenShipmentDetails={setShipmentDetailsId}
                        onRetryShipment={retryShipment}
                        onDeleteRedemption={onDeleteRedemption}
                        onQuestionnaireAnswers={onQuestionnaireAnswers}
                        onCancelShipment={cancelShipment}
                        selectedShipmentStatus={selectedShipmentStatus}
                        isSelected={!!selectedItems.find(s => s.id === redemption.id)}
                        triggerCopyNotifications={triggerCopyNotifications}
                        uniqueUrl={generateUniqueUrl(redemption.uniqueUrlId)}
                        exportAction={exportUnredeemedContact}
                        onSendEmail={reSendEmail}
                        isSimpleRedemption={page?.skipAddress}
                        redeemPage={page}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
          {showPagination && (
            <Grid container alignItems="center" className={classes.paginationContainer}>
              <Pagination {...pagination} startText="Show" endText="contacts" buttonClass={classes.paginationBtn} />
            </Grid>
          )}
        </div>

        <RedeemPageShareModal
          page={page}
          open={shareModalOpen}
          onClose={() => setShareModalOpen(false)}
          onSave={onSave}
          isSaving={updateRedeem.isLoading}
        />
        {summary && (
          <ProductSelectedDetails
            open={openProductDrawer && !isLoadingSummary}
            onClose={() => setOpenProductDrawer(false)}
            summary={summary}
            isCollection={page.collectionOnly}
            selectedProducts={selectedProducts}
          />
        )}
        <CreditDrawer
          open={openCreditDrawer}
          credit={creditSummary.current_balance}
          onClose={() => setOpenCreditDrawer(false)}
          company={company}
          history={history}
          recommendedCredits={recommendedCredits}
        />
        <AddNewCustomer
          open={isAddNewCustomerOpen}
          onClose={() => setIsAddNewCustomerOpen(false)}
          onSuccess={handleOnAddNewCustomersFromRecipient}
          setCsvImportError={setCsvImportError}
          entity="recipient"
          customMessage="The column headers for CSV file need to be in following order: first_name, last_name, email."
        />
        <ExistingRecipientsModal
          open={openExistingRecipientsModal}
          pageId={page.id}
          contactsToAdd={contactsToAdd}
          selectedItems={selectedItems}
          sendingCase={sendingCase}
          onClose={param => {
            setSendingCase();
            setSelectedItems([]);
            setOpenExistingRecipientsModal(false);
            setIsAddNewCustomerOpen(param?.retry);
            setTimeout(() => setUploadStatus(), 500);
          }}
          onReload={onReload}
          csvImportError={csvImportError}
          uploadKind={uploadStatus}
        />
        <SelectFromContactsDrawer
          open={openRecipientDrawer}
          onClose={() => setOpenRecipientDrawer(false)}
          onAddContacts={handleOnAddNewCustomersFromRecipient}
          page={page}
          newCustomers
          setOpenRecipientDrawer={setOpenRecipientDrawer}
          onAddNewCustomerDrawer={() => {
            setIsAddNewCustomerOpen(true);
            setOpenRecipientDrawer(false);
          }}
        />
        <ActionBar
          open={selectedItems.length > 0 && isUndefined(redemptionToDelete)}
          totalItemSelected={selectedItems.length}
          handleClose={() => {
            setSelectedItems([]);
            sendingCase();
          }}
          type="contact"
          actions={[
            {
              text: 'Send Email',
              tooltip: 'You can only send email to redemption placeholders.',
              action: () => reSendEmail(),
              disabled: selectedItems.some(r => r.contactId > 0 || isEmpty(r.emailAddress)),
              baseIcon: EmailOutlined,
              hoverIcon: Email
            },
            {
              text: 'Retry',
              tooltip: 'Only Insufficent Credit and Out Of Stock statuses can be retry for shipment.',
              action: () => retryShipment(),
              disabled: selectedItems.some(r => r.contactId < 0 || r.shipment?.shipmentId > 0),
              baseIcon: ReplayOutlined,
              hoverIcon: Replay
            },
            {
              text: 'Cancel',
              tooltip: 'You can only cancel shipments in the Scheduled and Pending Production status',
              action: () => cancelShipment(),
              disabled: selectedItems.some(r => !['Pending Production', 'Scheduled'].includes(r.shipment?.status)),
              baseIcon: BlockOutlined,
              hoverIcon: Block
            },
            {
              text: 'Delete',
              tooltip: 'You can only delete redemptions with no associated shipment',
              action: () => onDeleteRedemption(selectedItems),
              disabled: selectedItems.some(r => r.shipment?.shipmentId > 0),
              baseIcon: DeleteOutline,
              hoverIcon: Delete
            }
          ]}
        />
        <ShipmentDetailsModal shipmentId={shipmentDetailsId} onClose={() => setShipmentDetailsId(0)} />
        <RedemptionDeleteModal
          open={!isUndefined(redemptionToDelete)}
          onDelete={() => deleteRedemption(redemptionToDelete)}
          onClose={onDeleteRedemptionClose}
          redemption={redemptionToDelete}
        />
        <RedemptionQuestionnaire
          open={!isUndefined(redemptionQuestionnaire)}
          onClose={onRedemptionQuestionnaireClose}
          redemption={redemptionQuestionnaire}
        />
        <SpinningSnackBar
          open={
            cancelAPIShipments.isLoading ||
            retryRedeemShipment.isLoading ||
            deletePageRedemption.isLoading ||
            loadingHistory ||
            (updateRedeem.isLoading && customProcessingMessage)
          }
          message={getProcessingMessage(customProcessingMessage)}
        />
        {isLoading && <Loader absolute />}
      </CenteredGrid>
    </>
  );
};

export default RedeemPageHistory;
