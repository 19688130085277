import React from 'react';
import { Grid, withStyles, Typography, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMutation, useQueryClient } from 'react-query';
import { moneyStr, reorderDateWithSlicing } from '../../../../helpers/utils';
import Carousel from '../../../opportunities/components/Carousel';
import { productImageBasedOnStatus, StatusChip } from '../../../global/proofsCommon';
import { opportunityStatus } from '../../../../apis/constants';
import { StandardCard } from '../../../shared/containers/Cards';
import EditField from '../../../global/EditField';
import { orderApi } from '../../../../apis/swagup';

const styles = () => ({
  title: {
    lineHeight: 'normal',
    color: '#0f2440',
    marginTop: 16,
    marginBottom: 4
  },
  imageContainer: { margin: '16px 0 24px 0px' },
  imageContainerWithLeftNavbar: {
    '&>div': {
      width: '100% !important',
      display: 'flex',
      justifyContent: 'center'
    },
    '&>div>.carousel-image': {
      width: '300px !important'
    }
  },
  field: {
    color: '#0f2440',
    fontWeight: 500,
    marginTop: 12
  },
  blueLink: {
    paddingTop: 24,
    fontSize: 14,
    lineHeight: 0.71,
    color: '#3577d4',
    fontFamily: 'Gilroy-SemiBold',
    '&:hover': {
      color: '#004ddd'
    }
  }
});

const statusStyles = {
  [opportunityStatus.inDesign]: { color: '#8d9299', backgroundColor: '#f1f2f3' },
  [opportunityStatus.designReady]: { color: '#3577d4', backgroundColor: '#e7effa' },
  [opportunityStatus.changesRequested]: { color: '#fe8e26', backgroundColor: '#fff1e5' },
  [opportunityStatus.finalizingProofs]: { color: '#3577d4', backgroundColor: '#e7effa' },
  [opportunityStatus.proofsApproved]: { color: '#3577d4', backgroundColor: '#e7effa' },
  [opportunityStatus.finalizingProofsOld]: { color: '#3577d4', backgroundColor: '#e7effa' },
  [opportunityStatus.proofsApprovedOld]: { color: '#3577d4', backgroundColor: '#e7effa' },
  [opportunityStatus.approved]: { color: '#45af5f', backgroundColor: '#e9f5ec' }
};

const OpportunityCard = ({ opportunity, classes }) => {
  const queryClient = useQueryClient();

  const editNameMutation = useMutation(({ id, name }) => orderApi.patchOpportunity(id, { dashboard_name: name }), {
    onSuccess: () => queryClient.invalidateQueries([apiPaths.opportunities])
  });

  return (
    <StandardCard key={opportunity.id} data-testid={opportunity.id} className={classes.standardCard}>
      <StatusChip label={opportunity.status} status={statusStyles[opportunity.status]} />
      <Box style={{ padding: '4px 0px' }}>
        <EditField
          value={opportunity.dashboard_name || `Request #${opportunity.id}`}
          onEdit={name => editNameMutation.mutate({ id: opportunity.id, name })}
          isLoading={editNameMutation.isLoading}
          data-testid={`editfield-${opportunity.id}`}
        />
      </Box>
      {/* <Typography variant="h5" className={classes.title}>
          Request #{opportunity.id}
        </Typography> */}
      <Typography variant="body2">{opportunity.total_packs} items</Typography>
      <Grid className={classes.imageContainer}>
        <Carousel
          imageSources={opportunity.products.map(p => productImageBasedOnStatus(p.product, 256, 256))}
          imageLinkTo={`/orders-requested/${opportunity.id}`}
          width="100%"
          height={212}
        />
      </Grid>
      <Grid container justifyContent="space-between" className={classes.field}>
        <Typography variant="body2">Order Requested: </Typography>
        {reorderDateWithSlicing(opportunity.date)}
      </Grid>
      <Grid container justifyContent="space-between" className={classes.field}>
        <Typography variant="body2">Total: </Typography>
        {moneyStr(Number(opportunity.total_plus_tax) + opportunity.total_shipping + opportunity.total_warehouses)}
      </Grid>
      <Grid container justifyContent="center">
        <Link to={`/orders-requested/${opportunity.id}`} className={classes.blueLink}>
          View Request
        </Link>
      </Grid>
    </StandardCard>
  );
};

const RequestedList = ({ classes, opportunities }) => {
  // const { leftBarNavigation } = useFlags();

  return opportunities.map(opportunity => {
    return <OpportunityCard opportunity={opportunity} classes={classes} />;
  });
};

export default withStyles(styles)(RequestedList);
