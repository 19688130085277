import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useMembershipCheck from '../../../hooks/useMembershipCheck';
import { toSentenceCase } from '../../account/MembershipCommon';

const getOldestPlatform = connectedPlatforms => {
  // Check if data is an array and not empty
  if (!Array.isArray(connectedPlatforms) || connectedPlatforms.length === 0) {
    return null;
  }

  // Use dayjs to parse createdAt strings and find the minimum
  return connectedPlatforms.reduce((oldest, current) => {
    const currentDayjs = dayjs(current.createdAt).set('hour', 0);
    return !oldest || currentDayjs.isBefore(oldest) ? current : oldest;
  }, null);
};

const getDifferenceInDays = dateString => {
  // Check if dateString is valid
  if (!dayjs(dateString).isValid()) {
    return null;
  }

  const today = dayjs();
  const targetDate = dayjs(dateString);

  // Calculate the difference in days
  const differenceInDays = today.diff(targetDate, 'day');

  return differenceInDays;
};

const DAYS_OF_TRIAL = 30;
const DAYS_OF_NOTICE = 7;

const useIntegrationFreeTrial = selectedPlatform => {
  const [integrationsTrial, setIntegrationsTrial] = useState({ showWarning: false, showExpired: false });

  const membershipCheck = useMembershipCheck();

  const connectedPlatforms = useSelector(state => state.integrations);

  useEffect(() => {
    const hrisPlatforms = connectedPlatforms.filter(cp => cp.type === 'hris');

    const oldestPlatform = selectedPlatform || getOldestPlatform(hrisPlatforms);

    if (!isEmpty(oldestPlatform)) {
      const daysOld = getDifferenceInDays(oldestPlatform.createdAt);
      const daysToEnd = DAYS_OF_TRIAL - daysOld;
      const trialEnded = daysToEnd < 0;
      const trialAboutToEnd = daysToEnd <= DAYS_OF_NOTICE;

      setIntegrationsTrial({
        showWarning: !membershipCheck.integrationsAllowed && trialAboutToEnd,
        showExpired: !membershipCheck.integrationsAllowed && trialEnded,
        daysToEnd,
        integrationName: toSentenceCase(oldestPlatform.connectedPlatform),
        connected: oldestPlatform.connected
      });
    }
  }, [connectedPlatforms, membershipCheck, selectedPlatform]);

  return integrationsTrial;
};

export default useIntegrationFreeTrial;
