import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Helmet, CenteredGrid, Breadcrumbs } from '../shared';
import { usePresetPacks } from '../../hooks';
import tags from '../../apis/seoTags';
import Loader from '../global/Loader';
import PacksTemplates from './PacksTemplates';
import StarterPack from './StarterPack';
import styles from './styles/PresetHome';
import { useLeftNavbar } from '../../contexts/leftNavbar';
import { useAuth } from '../global/Authentication/AuthProvider';

const breadCrumbLinks = [{ title: 'Home', to: '/', absolute: true }, { title: 'Select Pack' }];

const useStyles = makeStyles(styles);

const PresetHome = () => {
  const { isAuthenticated } = useAuth();
  const { leftBarNavigation } = useFlags();
  const leftNavbarContext = useLeftNavbar();

  const leftNavOpen = leftNavbarContext?.leftNavOpen;

  const classes = useStyles();
  const { path, isExact } = useRouteMatch();
  const { data: packs, isLoading } = usePresetPacks({ enabled: isExact });

  if ((isExact && !packs) || isLoading) return <Loader />;

  return (
    <Switch>
      <Route path={path} exact>
        <div
          style={{
            paddingBottom: 180,
            overflowX: 'hidden',
            ...(!isAuthenticated && leftBarNavigation && leftNavOpen ? { marginLeft: 260 } : {})
          }}
        >
          <Helmet tags={tags.presetPacks} />
          <CenteredGrid container justifyContent="center" className={classes.center}>
            <Grid item xs={12} className={classes.breadcrumbContainer}>
              <Breadcrumbs links={breadCrumbLinks} />
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" variant="h4" className={classes.presetHomeTitle}>
                We’ve taken all of the work out of <br />
                building your swag packs
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" className={classes.presetHomeSubTitle} variant="body1">
                Start by selecting one of our preset packs, then tweak it or keep it as is, and we’ll design them to
                your brand.
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 24, paddingBottom: 24 }}>
              <PacksTemplates packs={packs} />
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" className={classes.bottomText} variant="body1">
                *Price based on 100 units and 1 color design.
              </Typography>
            </Grid>
          </CenteredGrid>
        </div>
      </Route>
      <Route path={`${path}/:slug`}>
        <div style={{ padding: !isAuthenticated ? '0px 100px' : '0px' }}>
          <StarterPack />
        </div>
      </Route>
    </Switch>
  );
};

export default PresetHome;
